html, body{
  overflow-y: auto !important;
  scrollbar-width: thin;
  scrollbar-color: #fff #fff;
}

.txt-primary{
  color:#007bff !important;
}
.txt-danger{
  color: #dc3545 !important;
}


.table.table-ellipsis tbody td {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
  }
  
  .table.table-ellipsis tbody td:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
  cursor: pointer;
  }

  .flag-row{
    background-color: rgba(240, 147, 55, 0.3) !important;
    color: #000 !important;
  }
  
  .warn-row{
    background-color:#fbdec2  !important;
    color: #000000 !important;
  }
  
  .flag-row a{
    color:#000000;
    font-weight: bold;
  }
  .small-caps{
    font-size: 11px !important;
  }
  .tx-12{
    font-size: 12px !important;
  }

  .overflow-auto{
    scrollbar-width:thin;
    scrollbar-color: #fff #fff;
    overflow: auto;
  }

  .h-200{
    max-height: 300px !important;
    min-height: 290px !important;
  }

  .hot-tr{
    cursor: pointer;
  }
  
  .hot-tr:hover{
    background-color: rgba(204, 204, 255, 0.5)  !important;;
    color:#000000
  }
  
  .hot-tr:hover a{
    color:#FFFFFF !important;
    font-weight: bold;
  }
  
  .h-300{
    max-height: 550px !important;
  }

  .hide{
    display: none !important;
  }

  a .nav-link .active { 
    color: #282c34 !important;
  }

  .button-listing{
    list-style: none;
    display:inline;
  }

  .button-listing li{
    padding-left: .5rem;
    float: left;
  }

  .table .action{
    display: none;
  }

  .table tr:hover .action{
    display: inline
  }

  .link{
    cursor: pointer;
  }

  .checklist-link{
    display: block;
    /* padding: 0.4rem 1rem; */
  }

  .list-description{
    list-style: none;
    padding: 0;
  }

  .list-description li{
    margin: auto;
    display: inline;
    padding-right: 5px;
  }
  
  .login-page-custom{
    background-color: #0069ff;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    position: fixed;
    overflow: auto;
  }
  .login-ad{
    color: #FFF;
    margin:100px
  }

  .login-ad h1{
    font-size: 50px;
    font-weight: bold;
  }
  .functions-list{
    font-size: 28px;
    list-style-type: '\2714';
    padding-left: 20px;
  }

  .login-logo-custom{
    color:#000; 
    font-weight:bolder;
    text-align: center;
  }
  .link{
    cursor: pointer;
    color: #007bff;
  }
  
  .cursor-pointer{
    cursor: pointer;
  }
